
import PropertyCard from "~/components/EntityCard/PropertyCard.vue";
import AddToListDialog from "~/components/list/AddToListDialog.vue";
import FigureText from "./FigureText.vue";
import { getPropertyAddress, mapPropertyRemarks } from "~/helpers/property-helpers.js";

export default {
  name: "Dashboard",

  components: {
    PropertyCard,
    AddToListDialog,
    FigureText,
  },

  props: {
    header: {
      type: Object,
      required: true,
    },

    propertyInfo: {
      type: Object,
      required: true,
    },

    property: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showAddToList: false,
      showAddNote: false,
    };
  },

  computed: {
    propertyToSave: function () {
      return [this.property.bfeNumber];
    },

    lists: function () {
      return this.property.listProperties?.map((list) => list.list) ?? [];
    },

    remarks: function () {
      return mapPropertyRemarks(this.property);
    },
  },

  methods: {
    openList(list) {
      this.$amplitude.track({
        event_type: "Open list",
        event_properties: {
          origin: "lookup",
        },
      });

      this.$router.push({
        name: "my-estaid-lists-id",
        params: { id: list.id },
      });
    },

    addNote() {
      this.showAddNote = true;
    },

    addPropertyToList() {
      this.showAddToList = true;
    },

    navigateToReferenceProperties() {
      const address = getPropertyAddress(this.property);
      this.$amplitude.track({
        event_type: "Reference ejendomme",
        event_prorpeties: {
          bfe: this.property.bfeNumber,
          postalCode: address?.postalCode,
        },
      });

      this.$router.push({
        name: "explore-reference-properties-id",
        params: { id: this.property.bfeNumber },
      });
    },

    getPropertyTypeString(type) {
      switch (type) {
        case "condo": {
          return this.$t("OVERVIEW_TYPE_CONDO");
        }
        case "propertyDividedInCondos": {
          return this.$t("OVERVIEW_TYPE_PROPERTY_WITH_CONDOS");
        }
        case "mainProperty": {
          return this.$t("OVERVIEW_TYPE_MAIN_PROPERTY");
        }
        case "buildingOnForeignPlot": {
          return this.$t("OVERVIEW_TYPE_BOFP");
        }
      }
    },

    getPropertyTypeIcon(type) {
      switch (type) {
        case "condo": {
          return "icon-condo-tt";
        }

        case "propertyDividedInCondos": {
          return "icon-condo-tt";
        }

        case "mainProperty": {
          return "icon-property-tt";
        }

        case "buildingOnForeignPlot": {
          return "icon-building-tt";
        }
      }
    },

    getTrackingRoute(type) {
      switch (type) {
        case "condo": {
          return "condo overview";
        }

        case "propertyDividedInCondos":
        case "mainProperty": {
          return "main property overview";
        }

        case "buildingOnForeignPlot": {
          return "bofp overview";
        }
      }
    },
  },
};
