
export default {
  inheritAttrs: false,

  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },

  computed: {
    activeTab: function () {
      return this.$route.name;
    },
  },

  methods: {
    navigateTo(route) {
      if (this.activeTab === `explore-property-id-${route}`) {
        return;
      }
      this.logAmplitudeEvent(route);
      this.$router.replace(this.getRoute(route));
    },

    getTabColor(route) {
      if (this.activeTab === `explore-property-id-${route}`) {
        return "--color-primary";
      }
      return "--color-white";
    },

    getActiveState(route) {
      return this.activeTab === `explore-property-id-${route}`;
    },

    logAmplitudeEvent(route) {
      this.$amplitude.track({
        event_type: `Navigate to property-${route}`,
        event_properties: {
          bfe: this.$route.params.id,
        },
      });
    },

    getRoute(path) {
      let name = "explore-property-id-" + path;

      return { name: name, params: { id: this.$route.params.id } };
    },
  },
};
