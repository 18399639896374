
export default {
  name: "FigureText",

  inheritAttrs: false,

  props: {
    items: {
      type: Array,
      required: true,
    },

    figure: {
      type: Object,
      required: true,
    },
  },

  computed: {
    shortenedItems() {
      return this.items.slice(0, 3);
    },

    numberOfExtraItems() {
      return this.items.length - 3;
    },
  },

  methods: {
    navigateTo(item) {
      this.$router.push({
        name: item.link.to,
        params: item.link.params,
      });
    },

    navigateToTable(id, event) {
      this.$router.push({
        name: "explore-property-id-buildings",
        params: this.$route.params,
        query: {
          activeTab: id,
        },
      });

      this.active = id;

      this.$amplitude.track({ event_type: `View commercial ${event}` });
    },
  },
};
